<template>
  <v-dialog
    style="z-index: 99999999999999999999999999999999999999999999"
    persistent
    :max-width="mdUp ? '50%' : '80%'"
    v-model="bDelete"
  >
    <!-- Start delete content -->
    <v-card
      style="margin-top: 30px; margin-bottom: 30px"
      class="DeleteCardHolder rounded-xl"
    >
      <!-- Start cancel -->
      <v-btn
        @click="close"
        class="ClosingDeleteButton"
        dark
        fab
        small
        icon
        color="black"
        rounded
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
      <!-- End cancel -->

      <!-- Start section title -->
      <v-layout row justify-center>
        <p class="DeleteTextTitle">Eliminar {{ sUnit }}</p>
      </v-layout>
      <!-- End section title -->
      <hr style="height: 1px; background-color: #ffce00; border: none" />
      <br />
      <!-- Start descriptions -->

      <v-layout v-if="sIdItem == 1" column align-center>
        <p class="DeleteTextAlerts">
          Eliminar un proyecto implica la eliminación de sus categorías y
          órdenes de compra adjuntas de forma irreversible.
        </p>
        <p style="margin-top: -12px" class="DeleteTextAlerts">
          ¿Desea continuar con esta acción?
        </p>
      </v-layout>
      <v-layout v-else column align-center>
        <p class="DeleteTextAlerts">{{ msgDelete }}</p>
        <p style="margin-top: -12px" class="DeleteTextAlerts">
          {{ msgQuestionDelete }}
        </p>
      </v-layout>

      <v-layout v-if="sIdItem == 1" column align-center>
        <div
          class="d-flex"
          style="font-size: 10px !important;justify-content:center;"
        >
          <v-checkbox v-model="chkDelete" color="#FFC556">
            <template v-slot:label>
              <div
                class="DeleteTextAlerts"
                style="font-size: 12px;color:#283C4D;"
              >
                Si, acepto la responsabilidad.
                <!-- Estoy de acuerdo con eliminar al proveedor y todo lo relacionado a él. -->
              </div>
            </template>
          </v-checkbox>
        </div>
      </v-layout>
      <!-- End descriptions -->
      <!-- Start actions -->
      <v-layout row justify-center>
        <v-layout row wrap class="ActionsLayout">
          <v-flex style="text-align: center" xs6 md6 sm6>
            <!-- Start cancel -->
            <v-btn @click="close" large outlined color="black">
              <p class="buttonOption">Cancelar</p>
            </v-btn>
            <!-- End cancel -->
          </v-flex>
          <v-flex style="text-align: center" xs6 sm6 md6>
            <!-- Start accept button -->
            <v-btn
              @click="deleteItem"
              :loading="bLoading"
              large
              depressed
              color="red"
              :disabled="!chkDelete"
            >
              <p style="color: white" class="buttonOption">Eliminar</p>
            </v-btn>
            <!-- End accept button -->
          </v-flex>
        </v-layout>
      </v-layout>
      <!-- End actions -->
    </v-card>
    <!-- End delete content -->
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    bLoading: false,
    chkDelete: false,
  }),
  methods: {
    // toggle function delete
    close() {
      this.$store.commit("setDeleteDialog", false);
    },
    deleteItem() {
      this.bLoading = true;
      db.delete(`${uri}` + this.sRoute, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.bLoading = false;
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          if (this.bReturn) {
            this.close();

            this.$router.push({ name: this.sToReturn });
          } else {
            this.$store.commit("refresher", true);
            this.close();
          }
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
  },
  props: {
    texts: Object,
    smUp: Boolean,
  },
  computed: {
    mdUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    // id to delete
    sId() {
      return this.$store.state.sDeleteId;
    },
    // unit for item
    sUnit() {
      return this.$store.state.sItem;
    },
    sIdItem() {
      return this.$store.state.idItem;
    },
    // route to call
    sRoute() {
      return this.$store.state.sDeleteRoute;
    },
    // return delete dialog state
    bDelete() {
      return this.$store.state.bDelete;
    },
    msgDelete() {
      return this.$store.state.msgDelete;
    },
    msgQuestionDelete() {
      return this.$store.state.msgQuestionDelete;
    },
    bReturn() {
      return this.$store.state.bReturn;
    },
    sToReturn() {
      return this.$store.state.sToReturn;
    },
  },
  watch: {
    bDelete() {
      if (this.bDelete) {
        this.chkDelete = this.sIdItem == 1 ? false : true;
      }
    },
  },
};
</script>
<style scoped>
.ActionsLayout {
  margin-top: 35px;
  max-width: 90%;
}
.ClosingDeleteButton {
  position: absolute;
  right: 0px;
  top: 0px;
  margin-right: 20px;
  margin-top: 20px;
}
.DeleteTextTitle {
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 35px;
  text-align: center;
  font-weight: 600;
}
.DeleteTextAlerts {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  text-align: center;
}

.DeleteCardHolder {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #ffffff;
  box-shadow: 0px 3px 40px #00000019;
}
</style>
